export const convertName = (req) => {
    switch (req) {
        case 'username':
            return 'Kullanıcı Adı';
        case 'password':
            return 'Şifre';
        case 'customerNumber':
            return 'Müşteri Numarası';
        default:
            return req;
    }
};

export const convertReq = (req) => {
    let copy = {};
    req.forEach((req) => {
        copy[req] = '';
    });

    return copy
}

export const getPeriodForDropdown = (periodList, companyValue) => {
    let period = [];
    periodList.forEach((item) => {
        if (item.company.value === companyValue) {
            item.periods.forEach((p) => {
                period.push({
                    title: p.label,
                    value: p.value
                });
            });
        }
    });
    return period;
}

export const getCompanyForDropdown = (data) => {
    let company = [];
    data.forEach((item) => {
        company.push({
            title: item.company.label,
            value: item.company.value
        });
    });
    return company;
};

export const deleteCompanyIsHere = (data, company) => {
    let copy = [];
    data.forEach((item) => {
        if (company.find((c) => c.companyCode === item.value) === undefined) {
            copy.push(item);
        }
    });
    return copy;
};

export const sortReq = (req, sortArray) => {
    let sortedKeys = Object.keys(req).sort(function(a, b) {
        return sortArray.indexOf(a) - sortArray.indexOf(b);
    });

    return sortedKeys;
}